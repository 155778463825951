import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HomePage from "../../Pages/Desktop/HomePage/HomePage";
import MHomePage from "../../Pages/Mobile/Home/MHomePage";
import { COMMON_ROUTES } from "../../Router/Path/CommonRoutes";
import { CKYCSlice } from "../../Store/Slice_Reducer/CKYC/CKYCSlice";
import { CarSlice } from "../../Store/Slice_Reducer/Car/CarSlice";
import { HealthSlice } from "../../Store/Slice_Reducer/Health/HealthSlice";
import { HomeSlice } from "../../Store/Slice_Reducer/Home/HomeSlice";
import { PIR_RESET_DATA } from "../../Store/Slice_Reducer/PIR/PirSlice";
import { TermSlice } from "../../Store/Slice_Reducer/Term/TermSlice";
import { TWSlice } from "../../Store/Slice_Reducer/TwoWheeler/TWSlice";
import { useAppDispatch } from "../../Store/hooks";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import { FRONTEND_DOMAIN } from "../../URLCollection/Domain";

function HomeContainer() {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(CKYCSlice.actions.BULK_UPDATE(CKYCSlice.getInitialState()));
    dispatch(HomeSlice.actions.setPageStatus(""));
    dispatch(CarSlice.actions.SET_CAR_SLICE(CarSlice.getInitialState()));
    dispatch(TWSlice.actions.SET_TW_SLICE(TWSlice.getInitialState()));
    dispatch(TermSlice.actions.BULK_UPDATE(TermSlice.getInitialState()));
    dispatch(
      HealthSlice.actions.SET_HEALTH_SLICE(HealthSlice.getInitialState())
    );
    dispatch(PIR_RESET_DATA());
  }, []);

  const navigateForm = (form_name: string) => {
    switch (form_name) {
      case "health":
        window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.HEALTH_FORM}`;
        break;
      case "car":
        window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.CAR_FORM}`;
        break;
      case "tw":
        window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.TW_FORM}`;
        break;
      case "term":
        window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.TERM_FORM}`;
        break;
      case "Home Insurance":
        window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.HOME_FORM}`;
        break;
      default:
        break;
    }
  };

  return isMobile ? (
    <MHomePage navigateForm={navigateForm} />
  ) : (
    <HomePage navigateForm={navigateForm} />
  );
}

export default HomeContainer;
